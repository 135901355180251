import {FC, useEffect, useState} from 'react'
import axios from 'axios'
import DatePicker from "react-datepicker";
import LessonsList from './LessonsList';
import TakenLessonsList from './TakenLessonsList';
import Messages from '../FunctionalComponents/HelperComponents/Messages'
import { formatDate, getPlanId, getEndDateForRegister, getStartDateForRegister, isOmega, getLessonPriceMSLC } from './HelperComponents/Functions';
import {Modal, Button} from 'react-bootstrap';
import Organisations from '../Enums/Organisations';
import moment from 'moment'
import { TakenLesson } from '../Types/types';


interface Lesson  
{
    subject:string,
    studentName:string,
    studentId:string,
    groupFee:number,
    groupPay:number,
    singleFee:number,
    singlePay:number
    lessonId:number,
    yearGroup:string,
    subjectId:number,
    lessonType:number
}

interface Props 
{
    data: Data
}

interface Data 
{
    config:Config,
    id:string,
    isAuthenticated:Boolean,
    organisationId: string | null,
    roleId: number | null
}

interface Config
{
    headers:Headers
}

interface Headers
{
    Authorization:string
}

interface LessonType 
{
    id: number,
    type: string
}

const Register: FC<Props> = ({data}) =>
{  
    
    const [lessons, setLessons] = useState<Lesson[] | null>([]);
    const [takenLessons, setTakenLessons] = useState<TakenLesson[] | null>([]);
    const [date, setDate] = useState<Date | null>(new Date());
    const [messages, setMessages] = useState<string[]>([]);  
    const [lessonTypes, setLessonTypes] = useState<LessonType[]>([]);  
    const [organisationId, setOrganisationId] = useState<number | null>(null)
    const [organisationPlanId, setOrganisationPlanId] = useState<number | null>(null)
    const [isProPlan, setIsProPlan] = useState<boolean>(false);
    const [allLessons, setAllLessons] = useState<Lesson[]>([]);
    const [dataId, setDataId] = useState<string>('');
    const [saving, setSaving] = useState<boolean>(false)
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [roleId, setRoleId] = useState<number | null>(null)
    //modal
    const [isOpen, setIsOpen] = useState<boolean>(false);

    //modal 2
    const [isOpen2, setIsOpen2] = useState<boolean>(false);


    function openModal(modalId:number = 0) {
        if(modalId > 0)
        {
            setIsOpen2(true)
            return
        }
        setIsOpen(true);
    }
    
    function closeModal(modalId: number = 0) {
        if(modalId > 0)
        {
            setIsOpen2(false)
            return
        }
        setIsOpen(false);
    }

    function setStartEndDates(d:string | null = null)
    {
        let date = d?  moment(d, "DD-MM-YYYY").toDate() :  moment().toDate();
        setStartDate(getStartDateForRegister(date))
        setEndDate(getEndDateForRegister(date))
    }
    useEffect(()=>
    {
        
        setDataId(data.id)
        setRoleId(data.roleId)
        const queryParams = new URLSearchParams(window.location.search)
        setStartEndDates(queryParams.get('date'))
        const _organisationId = queryParams.get('appId');
         if(_organisationId) 
         {
            
             axios.get(`/api/organisation/current-invoice-plan/${_organisationId}`, data.config).then(
                 (response)=>
             {
               
                 setOrganisationPlanId(response.data)
                 setIsProPlan(response.data === getPlanId("Business"))               
             })
             setOrganisationId(parseInt(_organisationId));
             getData(data, date, _organisationId, dataId.length > 0 ? dataId :  data.id)
         }        
        getLessonTypes();   
        // eslint-disable-next-line react-hooks/exhaustive-deps
        
    },[data]);

    const selectDate  = (date:Date | null) =>
    {
        setDate(date);
        getData(data, date);
    }

    const addLesson = (index:number, isExtraLesson: boolean = false) =>
    {   
        let _lessons = isExtraLesson? allLessons : lessons
        //console.log(date, organisationId, organisationPlanId        
        if(_lessons !==null && date !==null && organisationId !==null && organisationPlanId !==null)
        {
            var lesson = _lessons[index];
            var takenLesson:TakenLesson = 
            {    
                id:0,
                studentId:lesson.studentId,
                teacherId: dataId,
                subjectId:lesson.subjectId,
                singleFee:lesson.singleFee,
                groupFee:lesson.groupFee,
                singlePay:lesson.singlePay,
                groupPay:lesson.groupPay,
                totalFee:0,
                totalPay:0,
                studentName:lesson.studentName,
                hours:1,
                lessonType:lesson.lessonType,
                lessonDate:formatDate(date),
                takenLessonPaidStatus:0,
                takenLessonRefundStatus:0,
                organisationId:organisationId,
                invoicePlanId: organisationPlanId,
                subjectText: lesson.subject,
                stripeRefundId:null,
                invoiceId: null,
                lessonCode:0,
                isTaken: false,
                yearGroup: lesson.yearGroup,                
                takenLessonPaidStatusText: "un-paid"
            };

            if(takenLessons)
            {
                setTakenLessons([...takenLessons, takenLesson])
            }
            
        }
    }

    const getLessonTypes = () =>
    {
         axios.get('/api/lesson/lessonTypes', data.config)
         .then((response)=>
         {            
             setLessonTypes(response.data);
         })
    }

    const saveLessons = () =>
    {   
        setSaving(true)
        setTimeout(()=>
        {
                      
            getData(data, date);
            setSaving(false)
        }, 5000)
        if(organisationId === null)
        {
            addMessage("No organisation Id set")
            return 
        }

        if(date === null)
        {
            addMessage("Date is not valid")
            return 
        }

        takenLessons?.map((lesson, index)=>
        {
            if(lesson.hours <= 0)
            {
                addMessage("lesson hours must be greater than 0")
                return index
            }
            return index;
        })
        axios.post('/api/taken-lesson/add', {organisationId: organisationId, teacherId: dataId, lessons:takenLessons, date:formatDate(date)}, data.config)
        .catch((error)=>
        {
            alert('error')
            getData(data, date)
        })              
        addMessage("Lessons saved!")
    }

    const addMessage = (message:string) => 
    {
        setMessages([message])
        setTimeout(()=>
        {
            setMessages([])
        }, 3000)
    }

    const updateHours = (index:number, hours: string) => 
    {        
        if(takenLessons !==null)
        {
            var _takenLessons = takenLessons;
            _takenLessons[index].hours = parseFloat(hours);            
            setTakenLessons(_takenLessons)
        }     
    }

    const updateLessonType = (index:number, type: string) => 
    {
        if(takenLessons !==null)
        {            
            var lesson = takenLessons[index];
            lesson.lessonType = parseInt(type)

            if(organisationId?.toString() === Organisations.MSLC && parseInt(type) === 1)
            {
                var price = getLessonPriceMSLC(lesson, parseInt(type));
                if(price !==null)
                {
                    lesson.groupFee = price;
                    lesson.groupPay = getLessonPriceMSLC(lesson, parseInt(type), true);
                }else 
                {
                    alert('pricing error')
                }
            }
        }     
    }

    const updateLessonCode = (index:number, type: string) => 
    {
        if(takenLessons !== null)
        {
            var lesson = takenLessons[index];
            lesson.lessonCode = parseFloat(type)
        }     
    }

    const removeTakenLeson = async (index:number) => 
    {
        if(index > -1)
        {
            if(takenLessons !== null)
            {                             
                var newarray = await takenLessons.filter(x => takenLessons.indexOf(x) !==index)
                setTakenLessons(newarray)                 
            }                     
        }
    }

    const toggleLesson = async (index:number) => 
    {    
        if(index > -1)
        {
            if(takenLessons !== null)
            {  
                var lesson = takenLessons[index];
                lesson.isTaken = !lesson.isTaken
                setTakenLessons(takenLessons)
            }                     
        }
    }

    const getData =  (data:Data, date:Date | null, orgId: string | null = null, dId: string | null = null) =>
    { 
        let _organisationId = orgId?? organisationId;
        let teacherId = dId?? dataId; 
        if(date === null)
        {
            addMessage("Date is not valid")
            return
        }

        axios.get('/api/lesson/date/'+ teacherId + '/' + formatDate(date) + '/' + _organisationId, data.config)
            .then(response=>{
                var data = response.data
                setLessons(data)            
        })

        axios.get('/api/lesson/not-date/'+ teacherId + '/' + formatDate(date) + '/' + _organisationId, data.config)
            .then(response=>{
                var data = response.data
                setAllLessons(data)            
        })  

        axios.get('/api/taken-lesson/get/' + teacherId + '/' + formatDate(date) + '/' + _organisationId, data.config)
        .then(response=>{
            var data = response.data    
            setTakenLessons([]);                   
            setTakenLessons(data)
        })
    }  

    return(
    <div className='container'>   
    {
       isOmega(roleId) === true?
            <div className='mt-1 mb-5'>
                <label>Teacher ID : </label>
                <input className='input' onChange={(e)=>setDataId(e.target.value)}></input>
            </div>
        :null
    }
 
    <Messages messages={messages} />
        <div className='row mt-3'>        
                    <div className='col'>                  
                        <div className="mt-3">
                            <DatePicker                          
                            placeholderText='Select date' 
                            dateFormat={'dd/MM/yyyy'}
                            onChange={date => selectDate(date)}
                            minDate={startDate}
                            maxDate={endDate}
                            disabled={saving}
                            selected={date}/>
                        </div>          
                    </div>
                    <div className='col'>
                        <div className='mt-3 cta-btns'>
                            <button 
                                className='btn btn-secondary mr-3'
                                onClick={()=> openModal()}
                                >Add</button>                     

                            <button disabled={saving}
                                className='btn btn-info'
                                onClick={()=>saveLessons()}
                                >Save</button>                       
                        </div>
                    </div>
            </div>

            <div className='row mt-3'>
                <div className='col'>
                    <LessonsList
                        saving={saving} 
                        lessons={lessons} 
                        addLesson={addLesson}
                        isExtraLesson={false}
                        styling="register-table"/>                    
                </div>
                <div className='col'>
                    <TakenLessonsList 
                        isProPlan={isProPlan}
                        takenLessons={takenLessons} 
                        updateHours={updateHours} 
                        removeTakenLesson={removeTakenLeson}
                        toggleLesson={toggleLesson}
                        updateLessonType={updateLessonType}
                        updateLessonCode={updateLessonCode}
                        lessonTypes={lessonTypes}
                        />
                </div>
            </div>

            <Modal show={isOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                <Modal.Title>Add extra lessons</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='col'>
                    <LessonsList saving={saving} lessons={allLessons} addLesson={addLesson} isExtraLesson={true} styling=''/>  
                    </div>                     
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=>closeModal(0)}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isOpen2} onHide={()=>closeModal(1)}>
                <Modal.Header closeButton>
                <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Lessons not confirmed yet!       
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=>closeModal(1)}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>
    </div>);

}



export default Register;